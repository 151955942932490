export default async function Login({username, password, IP, Session}) {

    const url = "https://gops.azulhandling.com/LoginPage/procesos/login.php";
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            username, password, IP, Session
        }),
    })
    
    if(res.status == 200){
        return await res.json()
    }else{
        return "Error obteniendo los datos"
    }
}