export default async function ModifyInitialPassword({crewCode, password}) {

    const url = "https://gops.azulhandling.com/LoginPage/procesos/ChangeInitialPassword.php";
    const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            crewCode, password
        }),
    })
    
    if(res.status == 200){
        return await res.json()
    }else{
        return "Error obteniendo los datos"
    }

}